import React from 'react';

const About = () => {
  return (
    <div className="container mt-5 skills-section">
      <div className='row about-section'>
        <div className='col-sm'>
          <h4>
            Nicholas Porter, software developer based in Canada.
            <br></br>
            B.Eng. Software Engineering with over 2 years professional software development experience.
          </h4>
        </div>
      </div>
      <h2 className='skills-header mt-4'>Skills</h2>
      <div className='row skills-section'>
        <div className='col-sm info-box mt-3'>
          <h3>Full-Stack Web Development</h3>
          <ul>
            <li>Designed, implemented, and maintained multiple full-stack web applications using web frameworks 
              such as React.js, Node.js, Express.js, and Python Flask</li>
            <li>Knowledgeable designing and working with relational and non-relational databases, and working with ORMs such as Sequelize
              and SQLAlchemy</li>
          </ul>
        </div>
        <div className='col-sm-1'></div>
        <div className='col-sm info-box mt-3'>
          <h3>System Design and Implementation</h3>
          <ul>
            <li>Designed and implemented an ERP web application for a small manufacturing company to provide functionality such as job 
              tracking, quote generation, employee payroll, and reporting.</li>
            <li>Added reporting functionality to an invoicing web application for a municipality in Ontario</li>
          </ul>
        </div>
      </div>
      <div className='row skills-section'>
        <div className='col-sm info-box mt-3'>
          <h3>Data Analysis and Report Generation</h3>
          <ul>
            <li>Experienced aggregating and analyzing data from numerous sources (file systems, databases, online repositorys through API 
              requests, excel and pdf documents) using tools such as Powershell, Bash, Python, and Python libraries such as Pandas</li>
            <li>Ability to create robust and meaningful reports through web applications, excel, and pdf documents</li>
          </ul>
        </div>
        <div className='col-sm-1'></div>
        <div className='col-sm info-box mt-3'>
          <h3>Scripting and Process Automation</h3>
          <ul>
            <li>Experienced automating business processes involving repetitive manual steps such data retrieval and data entry</li>
            <li>High degree of familiarity scripting in both Windows and Linux environments</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default About;