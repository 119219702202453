import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppNavbar from './components/Navbar';
import About from './components/About';
import Blog from './components/Blog';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import './App.css';


const App = () => {
  return (
    <Router>
      <div>
        <AppNavbar />
        <Routes>
          <Route path="/" Component={About}>
          </Route>
          <Route path="/about" Component={About}>
          </Route>
          <Route path="/contact" Component={Contact}>
          </Route>
          <Route path="/blog" Component={Blog}>
          </Route>
          <Route path="/portfolio" Component={Portfolio}>
          </Route>
        </Routes>
      </div>
    </Router>
  );
};
export default App;